import { FetchError } from "configcat-common";
var HttpConfigFetcher = /** @class */ (function () {
    function HttpConfigFetcher() {
    }
    HttpConfigFetcher.prototype.handleStateChange = function (httpRequest, resolve, reject) {
        var _a;
        try {
            if (httpRequest.readyState === 4) {
                var statusCode = httpRequest.status, reasonPhrase = httpRequest.statusText;
                if (statusCode === 200) {
                    var eTag = (_a = httpRequest.getResponseHeader("ETag")) !== null && _a !== void 0 ? _a : void 0;
                    resolve({ statusCode: statusCode, reasonPhrase: reasonPhrase, eTag: eTag, body: httpRequest.responseText });
                }
                // The readystatechange event is emitted even in the case of abort or error.
                // We can detect this by checking for zero status code (see https://stackoverflow.com/a/19247992/8656352).
                else if (statusCode) {
                    resolve({ statusCode: statusCode, reasonPhrase: reasonPhrase });
                }
            }
        }
        catch (err) {
            reject(err);
        }
    };
    HttpConfigFetcher.prototype.fetchLogic = function (options, lastEtag) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                options.logger.debug("HttpConfigFetcher.fetchLogic() called.");
                var httpRequest_1 = new XMLHttpRequest();
                httpRequest_1.onreadystatechange = function () { return _this.handleStateChange(httpRequest_1, resolve, reject); };
                httpRequest_1.ontimeout = function () { return reject(new FetchError("timeout", options.requestTimeoutMs)); };
                httpRequest_1.onabort = function () { return reject(new FetchError("abort")); };
                httpRequest_1.onerror = function () { return reject(new FetchError("failure")); };
                httpRequest_1.open("GET", options.getUrl(), true);
                httpRequest_1.timeout = options.requestTimeoutMs;
                // NOTE: It's intentional that we don't specify the If-None-Match header.
                // The browser automatically handles it, adding it manually would cause an unnecessary CORS OPTIONS request.
                httpRequest_1.send(null);
            }
            catch (err) {
                reject(err);
            }
        });
    };
    return HttpConfigFetcher;
}());
export { HttpConfigFetcher };
