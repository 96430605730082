export function delay(delayMs, delayCleanup) {
    var timerId;
    var promise = new Promise(function (resolve) { return timerId = setTimeout(resolve, delayMs); });
    if (delayCleanup) {
        delayCleanup.clearTimer = function () { return clearTimeout(timerId); };
    }
    return promise;
}
export function errorToString(err, includeStackTrace) {
    if (includeStackTrace === void 0) { includeStackTrace = false; }
    return err instanceof Error
        ? includeStackTrace && err.stack ? err.stack : err.toString()
        : err + "";
}
export function isPromiseLike(obj) {
    var _a;
    // See also: https://stackoverflow.com/a/27746324/8656352
    return typeof ((_a = obj) === null || _a === void 0 ? void 0 : _a.then) === "function";
}
