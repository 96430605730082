import { Comparator } from "./ProjectConfig";
import * as semver from "./Semver";
import { sha1 } from "./Sha1";
import { errorToString } from "./Utils";
/** User Object. Contains user attributes which are used for evaluating targeting rules and percentage options. */
var User = /** @class */ (function () {
    function User(identifier, email, country, custom) {
        /** Custom attributes of the user for advanced targeting rule definitions (e.g. user role, subscription type, etc.) */
        this.custom = {};
        this.identifier = identifier;
        this.email = email;
        this.country = country;
        this.custom = custom || {};
    }
    return User;
}());
export { User };
var RolloutEvaluator = /** @class */ (function () {
    function RolloutEvaluator(logger) {
        this.logger = logger;
    }
    RolloutEvaluator.prototype.evaluate = function (setting, key, defaultValue, user, remoteConfig) {
        this.logger.debug("RolloutEvaluator.Evaluate() called.");
        // A negative setting type indicates a flag override (see also Setting.fromValue)
        if (setting.type < 0 && !isAllowedValue(setting.value)) {
            throw new TypeError(setting.value === null ? "Setting value is null." :
                setting.value === void 0 ? "Setting value is undefined." :
                    "Setting value '" + setting.value + "' is of an unsupported type (" + typeof setting.value + ").");
        }
        var eLog = new EvaluateLogger();
        eLog.user = user;
        eLog.keyName = key;
        eLog.returnValue = defaultValue;
        var result;
        try {
            if (user) {
                // evaluate comparison-based rules
                result = this.evaluateRules(setting.targetingRules, user, eLog);
                if (result !== null) {
                    eLog.returnValue = result.value;
                    return result;
                }
                // evaluate percentage-based rules
                result = this.evaluatePercentageRules(setting.percentageOptions, key, user);
                if (setting.percentageOptions && setting.percentageOptions.length > 0) {
                    eLog.opAppendLine("Evaluating % options => " + (!result ? "user not targeted" : "user targeted"));
                }
                if (result !== null) {
                    eLog.returnValue = result.value;
                    return result;
                }
            }
            else {
                if ((setting.targetingRules && setting.targetingRules.length > 0)
                    || (setting.percentageOptions && setting.percentageOptions.length > 0)) {
                    this.logger.targetingIsNotPossible(key);
                }
            }
            // regular evaluate
            result = {
                value: setting.value,
                variationId: setting.variationId
            };
            eLog.returnValue = result.value;
            return result;
        }
        finally {
            this.logger.settingEvaluated(eLog);
        }
    };
    RolloutEvaluator.prototype.evaluateRules = function (rolloutRules, user, eLog) {
        this.logger.debug("RolloutEvaluator.EvaluateRules() called.");
        if (rolloutRules && rolloutRules.length > 0) {
            var _loop_1 = function (i) {
                var rule = rolloutRules[i];
                var comparisonAttribute = this_1.getUserAttribute(user, rule.comparisonAttribute);
                var comparator = rule.comparator;
                var comparisonValue = rule.comparisonValue;
                var log = "Evaluating rule: '" + comparisonAttribute + "' " + this_1.ruleToString(comparator) + " '" + comparisonValue + "' => ";
                if (!comparisonAttribute) {
                    log += "NO MATCH (Attribute is not defined on the user object)";
                    eLog.opAppendLine(log);
                    return "continue";
                }
                var result = {
                    value: rule.value,
                    variationId: rule.variationId,
                    matchedTargetingRule: rule
                };
                switch (comparator) {
                    case Comparator.In:
                        var cvs = comparisonValue.split(",");
                        for (var ci = 0; ci < cvs.length; ci++) {
                            if (cvs[ci].trim() === comparisonAttribute) {
                                log += "MATCH";
                                eLog.opAppendLine(log);
                                return { value: result };
                            }
                        }
                        log += "no match";
                        break;
                    case Comparator.NotIn:
                        if (!comparisonValue.split(",").some(function (e) {
                            if (e.trim() === comparisonAttribute) {
                                return true;
                            }
                            return false;
                        })) {
                            log += "MATCH";
                            eLog.opAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case Comparator.Contains:
                        if (comparisonAttribute.indexOf(comparisonValue) !== -1) {
                            log += "MATCH";
                            eLog.opAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case Comparator.NotContains:
                        if (comparisonAttribute.indexOf(comparisonValue) === -1) {
                            log += "MATCH";
                            eLog.opAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case Comparator.SemVerIn:
                    case Comparator.SemVerNotIn:
                    case Comparator.SemVerLessThan:
                    case Comparator.SemVerLessThanEqual:
                    case Comparator.SemVerGreaterThan:
                    case Comparator.SemVerGreaterThanEqual:
                        if (this_1.evaluateSemver(comparisonAttribute, comparisonValue, comparator)) {
                            log += "MATCH";
                            eLog.opAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case Comparator.NumberEqual:
                    case Comparator.NumberNotEqual:
                    case Comparator.NumberLessThan:
                    case Comparator.NumberLessThanEqual:
                    case Comparator.NumberGreaterThan:
                    case Comparator.NumberGreaterThanEqual:
                        if (this_1.evaluateNumber(comparisonAttribute, comparisonValue, comparator)) {
                            log += "MATCH";
                            eLog.opAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    case Comparator.SensitiveOneOf: {
                        var values = comparisonValue.split(",");
                        var hashedComparisonAttribute = sha1(comparisonAttribute);
                        for (var ci = 0; ci < values.length; ci++) {
                            if (values[ci].trim() === hashedComparisonAttribute) {
                                log += "MATCH";
                                eLog.opAppendLine(log);
                                return { value: result };
                            }
                        }
                        log += "no match";
                        break;
                    }
                    case Comparator.SensitiveNotOneOf: {
                        var hashedComparisonAttribute_1 = sha1(comparisonAttribute);
                        if (!comparisonValue.split(",").some(function (e) {
                            if (e.trim() === hashedComparisonAttribute_1) {
                                return true;
                            }
                            return false;
                        })) {
                            log += "MATCH";
                            eLog.opAppendLine(log);
                            return { value: result };
                        }
                        log += "no match";
                        break;
                    }
                    default:
                        break;
                }
                eLog.opAppendLine(log);
            };
            var this_1 = this;
            for (var i = 0; i < rolloutRules.length; i++) {
                var state_1 = _loop_1(i);
                if (typeof state_1 === "object")
                    return state_1.value;
            }
        }
        return null;
    };
    RolloutEvaluator.prototype.evaluatePercentageRules = function (rolloutPercentageItems, key, user) {
        this.logger.debug("RolloutEvaluator.EvaluateVariations() called.");
        if (rolloutPercentageItems && rolloutPercentageItems.length > 0) {
            var hashCandidate = key + ((user.identifier === null || user.identifier === void 0) ? "" : user.identifier);
            var hashValue = sha1(hashCandidate).substring(0, 7);
            var hashScale = parseInt(hashValue, 16) % 100;
            var bucket = 0;
            for (var i = 0; i < rolloutPercentageItems.length; i++) {
                var percentageRule = rolloutPercentageItems[i];
                bucket += +percentageRule.percentage;
                if (hashScale < bucket) {
                    return {
                        value: percentageRule.value,
                        variationId: percentageRule.variationId,
                        matchedPercentageOption: percentageRule
                    };
                }
            }
        }
        return null;
    };
    RolloutEvaluator.prototype.evaluateNumber = function (v1, v2, comparator) {
        this.logger.debug("RolloutEvaluator.EvaluateNumber() called.");
        var n1, n2;
        if (v1 && !Number.isNaN(Number.parseFloat(v1.replace(",", ".")))) {
            n1 = Number.parseFloat(v1.replace(",", "."));
        }
        else {
            return false;
        }
        if (v2 && !Number.isNaN(Number.parseFloat(v2.replace(",", ".")))) {
            n2 = Number.parseFloat(v2.replace(",", "."));
        }
        else {
            return false;
        }
        switch (comparator) {
            case Comparator.NumberEqual:
                return n1 === n2;
            case Comparator.NumberNotEqual:
                return n1 !== n2;
            case Comparator.NumberLessThan:
                return n1 < n2;
            case Comparator.NumberLessThanEqual:
                return n1 <= n2;
            case Comparator.NumberGreaterThan:
                return n1 > n2;
            case Comparator.NumberGreaterThanEqual:
                return n1 >= n2;
            default:
                break;
        }
        return false;
    };
    RolloutEvaluator.prototype.evaluateSemver = function (v1, v2, comparator) {
        this.logger.debug("RolloutEvaluator.EvaluateSemver() called.");
        if (semver.valid(v1) == null || v2 === void 0) {
            return false;
        }
        v2 = v2.trim();
        switch (comparator) {
            case Comparator.SemVerIn:
                var sv = v2.split(",");
                var found = false;
                for (var ci = 0; ci < sv.length; ci++) {
                    if (!sv[ci] || sv[ci].trim() === "") {
                        continue;
                    }
                    if (semver.valid(sv[ci].trim()) == null) {
                        return false;
                    }
                    if (!found) {
                        found = semver.looseeq(v1, sv[ci].trim());
                    }
                }
                return found;
            case Comparator.SemVerNotIn:
                return !v2.split(",").some(function (e) {
                    if (!e || e.trim() === "") {
                        return false;
                    }
                    e = semver.valid(e.trim());
                    if (e == null) {
                        return false;
                    }
                    return semver.eq(v1, e);
                });
            case Comparator.SemVerLessThan:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.lt(v1, v2);
            case Comparator.SemVerLessThanEqual:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.lte(v1, v2);
            case Comparator.SemVerGreaterThan:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.gt(v1, v2);
            case Comparator.SemVerGreaterThanEqual:
                if (semver.valid(v2) == null) {
                    return false;
                }
                return semver.gte(v1, v2);
            default:
                break;
        }
        return false;
    };
    RolloutEvaluator.prototype.getUserAttribute = function (user, attribute) {
        switch (attribute) {
            case "Identifier":
                return user.identifier;
            case "Email":
                return user.email;
            case "Country":
                return user.country;
            default:
                return (user.custom || {})[attribute];
        }
    };
    RolloutEvaluator.prototype.ruleToString = function (rule) {
        switch (rule) {
            case Comparator.In:
                return "IS ONE OF";
            case Comparator.NotIn:
                return "IS NOT ONE OF";
            case Comparator.Contains:
                return "CONTAINS";
            case Comparator.NotContains:
                return "DOES NOT CONTAIN";
            case Comparator.SemVerIn:
                return "IS ONE OF (SemVer)";
            case Comparator.SemVerNotIn:
                return "IS NOT ONE OF (SemVer)";
            case Comparator.SemVerLessThan:
                return "< (SemVer)";
            case Comparator.SemVerLessThanEqual:
                return "<= (SemVer)";
            case Comparator.SemVerGreaterThan:
                return "> (SemVer)";
            case Comparator.SemVerGreaterThanEqual:
                return ">= (SemVer)";
            case Comparator.NumberEqual:
                return "= (Number)";
            case Comparator.NumberNotEqual:
                return "!= (Number)";
            case Comparator.NumberLessThan:
                return "< (Number)";
            case Comparator.NumberLessThanEqual:
                return "<= (Number)";
            case Comparator.NumberGreaterThan:
                return "> (Number)";
            case Comparator.NumberGreaterThanEqual:
                return ">= (Number)";
            case Comparator.SensitiveOneOf:
                return "IS ONE OF (Sensitive)";
            case Comparator.SensitiveNotOneOf:
                return "IS NOT ONE OF (Sensitive)";
            default:
                return rule + "";
        }
    };
    return RolloutEvaluator;
}());
export { RolloutEvaluator };
var EvaluateLogger = /** @class */ (function () {
    function EvaluateLogger() {
        this.operations = "";
    }
    EvaluateLogger.prototype.opAppendLine = function (s) {
        this.operations += " " + s + "\n";
    };
    EvaluateLogger.prototype.toString = function () {
        return "Evaluate '" + this.keyName + "'"
            + "\n User : " + JSON.stringify(this.user)
            + "\n" + this.operations
            + " Returning value : " + this.returnValue;
    };
    return EvaluateLogger;
}());
/* Helper functions */
function evaluationDetailsFromEvaluateResult(key, evaluateResult, fetchTime, user) {
    return {
        key: key,
        value: evaluateResult.value,
        variationId: evaluateResult.variationId,
        fetchTime: fetchTime,
        user: user,
        isDefaultValue: false,
        matchedEvaluationRule: evaluateResult.matchedTargetingRule,
        matchedEvaluationPercentageRule: evaluateResult.matchedPercentageOption,
    };
}
export function evaluationDetailsFromDefaultValue(key, defaultValue, fetchTime, user, errorMessage, errorException) {
    return {
        key: key,
        value: defaultValue,
        fetchTime: fetchTime,
        user: user,
        isDefaultValue: true,
        errorMessage: errorMessage,
        errorException: errorException
    };
}
export function evaluate(evaluator, settings, key, defaultValue, user, remoteConfig, logger) {
    var errorMessage;
    if (!settings) {
        errorMessage = logger.configJsonIsNotPresentSingle(key, "defaultValue", defaultValue).toString();
        return evaluationDetailsFromDefaultValue(key, defaultValue, getTimestampAsDate(remoteConfig), user, errorMessage);
    }
    var setting = settings[key];
    if (!setting) {
        errorMessage = logger.settingEvaluationFailedDueToMissingKey(key, "defaultValue", defaultValue, keysToString(settings)).toString();
        return evaluationDetailsFromDefaultValue(key, defaultValue, getTimestampAsDate(remoteConfig), user, errorMessage);
    }
    var evaluateResult = evaluator.evaluate(setting, key, defaultValue, user, remoteConfig);
    if (defaultValue !== null && defaultValue !== void 0 && typeof defaultValue !== typeof evaluateResult.value) {
        throw new TypeError("The type of a setting must match the type of the given default value.\nThe setting's type was " + typeof defaultValue + ", the given default value's type was " + typeof evaluateResult.value + ".\nPlease pass a corresponding default value type.");
    }
    return evaluationDetailsFromEvaluateResult(key, evaluateResult, getTimestampAsDate(remoteConfig), user);
}
export function evaluateAll(evaluator, settings, user, remoteConfig, logger, defaultReturnValue) {
    var errors;
    if (!checkSettingsAvailable(settings, logger, defaultReturnValue)) {
        return [[], errors];
    }
    var evaluationDetailsArray = [];
    for (var _i = 0, _a = Object.entries(settings); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], setting = _b[1];
        var evaluationDetails = void 0;
        try {
            var evaluateResult = evaluator.evaluate(setting, key, null, user, remoteConfig);
            evaluationDetails = evaluationDetailsFromEvaluateResult(key, evaluateResult, getTimestampAsDate(remoteConfig), user);
        }
        catch (err) {
            errors !== null && errors !== void 0 ? errors : (errors = []);
            errors.push(err);
            evaluationDetails = evaluationDetailsFromDefaultValue(key, null, getTimestampAsDate(remoteConfig), user, errorToString(err), err);
        }
        evaluationDetailsArray.push(evaluationDetails);
    }
    return [evaluationDetailsArray, errors];
}
export function checkSettingsAvailable(settings, logger, defaultReturnValue) {
    if (!settings) {
        logger.configJsonIsNotPresent(defaultReturnValue);
        return false;
    }
    return true;
}
export function isAllowedValue(value) {
    return value === null
        || value === void 0
        || typeof value === "boolean"
        || typeof value === "number"
        || typeof value === "string";
}
export function getTimestampAsDate(projectConfig) {
    return projectConfig ? new Date(projectConfig.timestamp) : void 0;
}
function keysToString(settings) {
    return Object.keys(settings).map(function (key) { return "'" + key + "'"; }).join(", ");
}
