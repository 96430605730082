var ProjectConfig = /** @class */ (function () {
    function ProjectConfig(configJson, config, timestamp, httpETag) {
        this.configJson = configJson;
        this.config = config;
        this.timestamp = timestamp;
        this.httpETag = httpETag;
    }
    ProjectConfig.equals = function (projectConfig1, projectConfig2) {
        // When both ETags are available, we don't need to check the JSON content.
        return projectConfig1.httpETag && projectConfig2.httpETag
            ? projectConfig1.httpETag === projectConfig2.httpETag
            : projectConfig1.configJson === projectConfig2.configJson;
    };
    ProjectConfig.prototype.with = function (timestamp) { return new ProjectConfig(this.configJson, this.config, timestamp, this.httpETag); };
    Object.defineProperty(ProjectConfig.prototype, "isEmpty", {
        get: function () { return !this.config; },
        enumerable: false,
        configurable: true
    });
    ProjectConfig.prototype.isExpired = function (expirationMs) {
        return this === ProjectConfig.empty || this.timestamp + expirationMs < ProjectConfig.generateTimestamp();
    };
    ProjectConfig.generateTimestamp = function () {
        return new Date().getTime();
    };
    ProjectConfig.serialize = function (config) {
        var _a, _b;
        return config.timestamp + "\n"
            + ((_a = config.httpETag) !== null && _a !== void 0 ? _a : "") + "\n"
            + ((_b = config.configJson) !== null && _b !== void 0 ? _b : "");
    };
    ProjectConfig.deserialize = function (value) {
        var separatorIndices = Array(2);
        var index = 0;
        for (var i = 0; i < separatorIndices.length; i++) {
            index = value.indexOf("\n", index);
            if (index < 0) {
                throw new Error("Number of values is fewer than expected.");
            }
            separatorIndices[i] = index++;
        }
        var endIndex = separatorIndices[0];
        var slice = value.substring(0, endIndex);
        var fetchTime = parseInt(slice);
        if (isNaN(fetchTime)) {
            throw new Error("Invalid fetch time: " + slice);
        }
        index = endIndex + 1;
        endIndex = separatorIndices[1];
        slice = value.substring(index, endIndex);
        var httpETag = slice.length > 0 ? slice : void 0;
        index = endIndex + 1;
        slice = value.substring(index);
        var config;
        var configJson;
        if (slice.length > 0) {
            try {
                config = new Config(JSON.parse(slice));
            }
            catch (_a) {
                throw new Error("Invalid config JSON content: " + slice);
            }
            configJson = slice;
        }
        return new ProjectConfig(configJson, config, fetchTime, httpETag);
    };
    ProjectConfig.serializationFormatVersion = "v2";
    ProjectConfig.empty = new ProjectConfig(void 0, void 0, 0, void 0);
    return ProjectConfig;
}());
export { ProjectConfig };
var Config = /** @class */ (function () {
    function Config(json) {
        this.settings = json.f
            ? Object.fromEntries(Object.entries(json.f).map(function (_a) {
                var key = _a[0], value = _a[1];
                return [key, new Setting(value)];
            }))
            : {};
        this.preferences = json.p ? new Preferences(json.p) : void 0;
    }
    return Config;
}());
export { Config };
export var RedirectMode;
(function (RedirectMode) {
    RedirectMode[RedirectMode["No"] = 0] = "No";
    RedirectMode[RedirectMode["Should"] = 1] = "Should";
    RedirectMode[RedirectMode["Force"] = 2] = "Force";
})(RedirectMode || (RedirectMode = {}));
var Preferences = /** @class */ (function () {
    function Preferences(json) {
        this.baseUrl = json.u;
        this.redirectMode = json.r;
    }
    return Preferences;
}());
export { Preferences };
/** Setting type. */
export var SettingType;
(function (SettingType) {
    /** On/off type (feature flag). */
    SettingType[SettingType["Boolean"] = 0] = "Boolean";
    /** Text type. */
    SettingType[SettingType["String"] = 1] = "String";
    /** Whole number type. */
    SettingType[SettingType["Int"] = 2] = "Int";
    /** Decimal number type. */
    SettingType[SettingType["Double"] = 3] = "Double";
})(SettingType || (SettingType = {}));
var Setting = /** @class */ (function () {
    function Setting(json) {
        var _a, _b, _c, _d;
        this.value = json.v;
        this.type = json.t;
        this.percentageOptions = (_b = (_a = json.p) === null || _a === void 0 ? void 0 : _a.map(function (item) { return new RolloutPercentageItem(item); })) !== null && _b !== void 0 ? _b : [];
        this.targetingRules = (_d = (_c = json.r) === null || _c === void 0 ? void 0 : _c.map(function (item) { return new RolloutRule(item); })) !== null && _d !== void 0 ? _d : [];
        this.variationId = json.i;
    }
    Setting.fromValue = function (value) {
        return new Setting({
            t: -1,
            v: value,
        });
    };
    return Setting;
}());
export { Setting };
/** Targeting rule comparison operator. */
export var Comparator;
(function (Comparator) {
    /** Does the comparison value interpreted as a comma-separated list of strings contain the comparison attribute? */
    Comparator[Comparator["In"] = 0] = "In";
    /** Does the comparison value interpreted as a comma-separated list of strings not contain the comparison attribute? */
    Comparator[Comparator["NotIn"] = 1] = "NotIn";
    /** Is the comparison value contained by the comparison attribute as a substring? */
    Comparator[Comparator["Contains"] = 2] = "Contains";
    /** Is the comparison value not contained by the comparison attribute as a substring? */
    Comparator[Comparator["NotContains"] = 3] = "NotContains";
    /** Does the comparison value interpreted as a comma-separated list of semantic versions contain the comparison attribute? */
    Comparator[Comparator["SemVerIn"] = 4] = "SemVerIn";
    /** Does the comparison value interpreted as a comma-separated list of semantic versions not contain the comparison attribute? */
    Comparator[Comparator["SemVerNotIn"] = 5] = "SemVerNotIn";
    /** Is the comparison value interpreted as a semantic version less than the comparison attribute? */
    Comparator[Comparator["SemVerLessThan"] = 6] = "SemVerLessThan";
    /** Is the comparison value interpreted as a semantic version less than or equal to the comparison attribute? */
    Comparator[Comparator["SemVerLessThanEqual"] = 7] = "SemVerLessThanEqual";
    /** Is the comparison value interpreted as a semantic version greater than the comparison attribute? */
    Comparator[Comparator["SemVerGreaterThan"] = 8] = "SemVerGreaterThan";
    /** Is the comparison value interpreted as a semantic version greater than or equal to the comparison attribute? */
    Comparator[Comparator["SemVerGreaterThanEqual"] = 9] = "SemVerGreaterThanEqual";
    /** Is the comparison value interpreted as a number equal to the comparison attribute? */
    Comparator[Comparator["NumberEqual"] = 10] = "NumberEqual";
    /** Is the comparison value interpreted as a number not equal to the comparison attribute? */
    Comparator[Comparator["NumberNotEqual"] = 11] = "NumberNotEqual";
    /** Is the comparison value interpreted as a number less than the comparison attribute? */
    Comparator[Comparator["NumberLessThan"] = 12] = "NumberLessThan";
    /** Is the comparison value interpreted as a number less than or equal to the comparison attribute? */
    Comparator[Comparator["NumberLessThanEqual"] = 13] = "NumberLessThanEqual";
    /** Is the comparison value interpreted as a number greater than the comparison attribute? */
    Comparator[Comparator["NumberGreaterThan"] = 14] = "NumberGreaterThan";
    /** Is the comparison value interpreted as a number greater than or equal to the comparison attribute? */
    Comparator[Comparator["NumberGreaterThanEqual"] = 15] = "NumberGreaterThanEqual";
    /** Does the comparison value interpreted as a comma-separated list of hashes of strings contain the hash of the comparison attribute? */
    Comparator[Comparator["SensitiveOneOf"] = 16] = "SensitiveOneOf";
    /** Does the comparison value interpreted as a comma-separated list of hashes of strings not contain the hash of the comparison attribute? */
    Comparator[Comparator["SensitiveNotOneOf"] = 17] = "SensitiveNotOneOf";
})(Comparator || (Comparator = {}));
var RolloutRule = /** @class */ (function () {
    function RolloutRule(json) {
        this.order = json.o;
        this.comparisonAttribute = json.a;
        this.comparator = json.t;
        this.comparisonValue = json.c;
        this.value = json.v;
        this.variationId = json.i;
    }
    return RolloutRule;
}());
export { RolloutRule };
var RolloutPercentageItem = /** @class */ (function () {
    function RolloutPercentageItem(json) {
        this.order = json.o;
        this.percentage = json.p;
        this.value = json.v;
        this.variationId = json.i;
    }
    return RolloutPercentageItem;
}());
export { RolloutPercentageItem };
